import { type SerializeFrom } from '@remix-run/node'
import { useRouteLoaderData } from '@remix-run/react'
import { type loader as rootLoader } from '#app/root'

type AdminLoaderData = Awaited<
	ReturnType<Awaited<ReturnType<typeof rootLoader>>['json']>
>
export type AdminUser = NonNullable<AdminLoaderData['user']>

function isUser(user: any): user is SerializeFrom<typeof rootLoader>['user'] {
	return user && typeof user === 'object' && typeof user.id === 'string'
}

export function useOptionalUser(): AdminUser | undefined {
	const data = useRouteLoaderData<typeof rootLoader>('root')
	if (!data || !data.user || !isUser(data.user)) {
		return undefined
	}
	return data.user
}

export function useUser(): AdminUser {
	const maybeUser = useOptionalUser()
	if (!maybeUser) {
		throw new Error(
			'No user found in loader, but user is required by useUser. If user is optional, try useOptionalUser instead.',
		)
	}
	return maybeUser
}

type Action = 'create' | 'read' | 'update' | 'delete'
type Entity = 'user' | 'note'
type Access = 'own' | 'any' | 'own,any' | 'any,own'
export type PermissionString =
	| `${Action}:${Entity}`
	| `${Action}:${Entity}:${Access}`

export function parsePermissionString(permissionString: PermissionString) {
	const [action, entity, access] = permissionString.split(':') as [
		Action,
		Entity,
		Access | undefined,
	]
	return {
		action,
		entity,
		access: access ? (access.split(',') as Array<Access>) : undefined,
	}
}

export function userHasPermission(
	user:
		| Pick<ReturnType<typeof useUser>, 'roles' | 'organizations'>
		| null
		| undefined,
	permission: PermissionString,
) {
	if (!user) return false
	const { action, entity, access } = parsePermissionString(permission)

	const userRoles = user.roles.some((role) =>
		role.permissions.some(
			(permission) =>
				permission.entity === entity &&
				permission.action === action &&
				(!access || access.includes(permission.access)),
		),
	)

	const organizationRoles = user.organizations.some((organization) => {
		return organization.roles.some((role) =>
			role.permissions.some(
				(permission) =>
					permission.entity === entity &&
					permission.action === action &&
					(!access || access.includes(permission.access)),
			),
		)
	})

	return userRoles || organizationRoles
}

export function userHasRole(
	user: Pick<ReturnType<typeof useUser>, 'roles' | 'organizations'> | null,
	role: string,
) {
	if (!user) return false

	const userRoles = user.roles.some((r) => r.name === role)
	const organizationRoles = user.organizations.some((o) =>
		o.roles.some((r) => r.name === role),
	)

	return userRoles || organizationRoles
}
